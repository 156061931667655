import React, { useContext } from "react";
import { ThemeApi } from "../../context/themeContext";
import BackBtn from "../../utils/BackBtn";
import Heading from "../../utils/Heading";
import Hr from "../../utils/Hr";
import Info from "./Info/Info";
import PersonalInfo from "./PersonalInfo";
import Stats from "./Stats/Stats";

const About = () => {
  const { theme } = useContext(ThemeApi);
  let text = {
    uz: "MEN HAQIMDA",
    ru: "ОБО МНЕ",
    en: "ABOUT ME",
  };

  return (
    <div
      className={`w-full h-screen ${
        theme === "light" ? "bg-light1 text-dark1" : "bg-dark1 text-light1"
      }`}>
      <div className="container">
        <BackBtn />
        <Heading text={text} />
        <div className="w-full flex justify-center items-center mb-5 sm:hidden">
          <img
            src="https://ik.imagekit.io/ekbcszw7tx/Portfolio/new_me_zoom.jpg"
            width="288"
            className="sm:w-72 rounded-full"
            alt="myImage"
          />
        </div>
      </div>
      <div
        className={`container sm:px-5 lg:px-20 px-7 pt-5 pb-10 ${
          theme === "light" ? "bg-light2 text-dark2" : "bg-dark2 text-light2"
        }`}>
        <PersonalInfo />
        <Hr />
        <Info />
        <Hr />
        <Stats />
      </div>
    </div>
  );
};

export default About;
